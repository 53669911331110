/*
.blue_wrapper {
    background-color: #CBDCF4;
    padding-top: 20%;
}

.white_wrapper {
    background-color: white;
    padding-top: 20%;
}
*/

h2:hover {
    pointer-events: none !important;
}

.Portfolio img {
    max-width: 1900px;
}

.Portfolio {
    margin-left: auto;
    margin-right: auto;
}

.links {
    display: flex;
    flex-direction: column;
    padding: 2em 5%;
    color: white;
    background-color: #9FA69A; 
}
.links a:link {
    color: white;
}
.links h3 {
    margin-block: 0 1em;
}
.links h3:hover {
    color: white;
    cursor: default;
}
.links h5 {
    margin-block: 0 0.25em;
}
.links h5:hover {
    color:#CBDCF4;
    cursor: pointer;
}

.module {
    display: flex;
    flex-direction: column;
    padding-top: 10%;
    color: #263632;
    width: 100%;
}

.imgmodule {
    display: flex;
    flex-direction: row;
    padding-top: 2%;
    justify-content: flex-end;
}

.modBlock {
    display: flex;
    flex-direction: column;
    margin: 0 5%;

}
.modBlock h2 {
    margin-block: 0 0;
    pointer-events: none !important;
}
.modBlock h5 {
    margin-block: 0.5em 0;
}
.modBlock p {
    margin-block: 0.5em 0;
}

.imgBlock {
    padding: 5% 5% 0;
    overflow: hidden;
}

.scrollBlock {
    padding: 5% 5% 0;
    overflow: scroll;
}

.figure {
    height: auto;
    width: 100%;
    border-radius: 10px;
    /*box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);*/
}
.noshadow {
    width: 100%;
    height: auto;
    box-shadow: none;
}

#ds1, #ds2 {
    height: auto;
    width: 100%;
    overflow: hidden;
}

#portfolioimg {
    height: auto;
    width: 200%;
}

#note {
    margin: 5% 5% 0;
    width: 90%;
    font-size: small;
}

#lc8 img, #lc6 img {
    border-radius: 0;
}

/* Tablet and Desktop Styling */
@media screen and (min-width: 500px){
    .module {
        flex-direction: column;
        margin-right: auto;
        margin-left: auto;
        padding-top: 5%;
    }

    .imgBlock {
        max-width: 500px;
        margin: auto;
    }
}

@media screen and (min-width: 700px){
    .scrollBlock {
        overflow: hidden;
        padding: 0 5%;
        max-height: 90vh;
    }

    .scrollBlock img {
        max-width: 100%;
        padding-top: 5%;
    }
}

@media screen and (min-width: 900px){
    /*
    .blue_wrapper {
        background-color: #CBDCF4;
        padding-top: 10%;
    }
    
    .white_wrapper {
        background-color: white;
        padding-top: 10%;
    }
    */

    #note {
        width: 50%;
    }

    .module0 {
        flex-direction: row;
        padding-top: 5%;
    }

    .modBlock {
        display: flex;
        margin: 0 5%;
        min-width: 40%;
    }
    .modBlock p {
        /*width: 50%;*/
    }

    .module {
        flex-direction: row;
        justify-content: space-between;
    }

    .imgmodule {
        flex-direction: row;
        justify-content: flex-end;
        margin-right: auto;
        margin-left: auto;
        padding-top: 5%;
    }

    .imgBlock {
        display: flex;
        justify-content: flex-end;
        padding: 0 5%;
        width: 100%;
    }

    .imgBlock img {
        max-width: 500px;
    }

    .scrollBlock {
        overflow: hidden;
        padding: 0 5%;
        max-height: 90vh;
    }

    .noshadow {
        width: 100%;
        height: auto;
        box-shadow: none;
    }

    .figure {
        width: 100%;
        height: auto;
    }
    

    #ip1 {
        max-width: 1900px;
    }

    #wp1 {
        max-width: 1900px;
    }
    #wp2 {
        max-width: 1300px;
    }
    #ep1 {
        max-width: 1900px;
    }
    #ep2 {
        max-width: 1300px;
    }

    #a0 {
        max-width: 1900px;
    }

    #rp1, #rp3, #rp5, #rp7 {
        max-width: 1900px;
    }

    #re1, #re2 {
        max-width: 1900px;
        /*margin: 0 0 0 auto;*/
    }
    
    #mr2, #mr4, #mr5, #mr9 {
        max-width: 1900px;
    }

    #mr5, #mr10 {
        max-width: 1900px;
        margin: 0 0 0 auto;
    }

    #mr8 {
        width: 100%;
        max-height: 100%;
        overflow: auto;
    }

    #ds0 {
        max-width: 850px;
        margin-left: 3%;
    }
    #ds1, #ds2 {
        max-width: 1700px;
    }

    #portfolioimg {
        /*height: auto;
        width: 100%;*/
        max-width: 100%;
        max-height: 100%;
    }

    #lc9 .imgBlock, #ip0 .imgBlock {
        display: block;
    }
}
