#root {
    width: 100%;
    height: 100%;
}

.App {
    width: 100%;
    height: 100%;
}

.buffer {
    display: flex;
    height: 80px;
}
.half_buffer {
    display: flex;
    height: 35px;
}

@media screen and (min-width: 500px){
    .buffer {
        height: 100px;
    }
}