.banner {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.banner header {
    text-align: end;
}

.banner header:hover {
    color: #CBDCF4;
    cursor: pointer;
}

h3:hover {
    color: #CBDCF4;
    cursor: pointer;
}

.announcement {
    background-color: rgba(159, 166, 154, 1);
    min-height: 25px;
    color: #fff;
    letter-spacing: 1px;
    padding: 1.5% 2.5%;
    display: flex;
    justify-content: flex-end;
    font: 700 10px/18px Roboto, -apple-system, Roboto, Helvetica, sans-serif;
}

.iconContainer {
    display: flex;
    padding: 1.5% 4%;
}

.iconWrapper {
    background-color: #fff;
    display: flex;
    height: 35px;
    flex-grow: 1;
    gap: 10px;
    align-items: center;
}

.icon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 20px;
}

.lastIcon {
    composes: icon;
    align-self: start;
    margin-top: 7px;
}

svg {
    fill: white;
    stroke: #263632;
}
svg:hover {
    stroke: #458ff8 !important;
    fill: white;
    cursor: pointer;
}

/*
*
* // website styling defaults to mobile platforms, alternative styling applied for desktop...
*/
@media screen and (min-width: 500px) {
    .iconContainer {
        padding: 10px 40px;
    }

    .iconWrapper {
        gap: 15px;
    }

    h3 {
        font-size: small;
    }
}

@media screen and (min-width: 900px){
    h3 {
        font-size: medium;
    }
}

@media screen and (min-width: 1500px){
    h3 {
        font-size: large;
    }
}