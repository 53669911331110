header {
    width: 100%;
}

.header {
    position: absolute;
    display: flex;
    width: 100%;
    height: 80px;
    z-index: 10;
    flex-direction: row;
    justify-content: space-between;
    color: #263632;
    font: 600 10px/1.4 Inter, sans-serif;
}

.header .container {
    background-color: #fff;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    /*padding: 31px 39px 5px;*/
    padding: 5% 5% 0.5% 5%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.header .icon {
    /*aspect-ratio: 1.2;*/
    object-fit: contain;
    object-position: center;
    width: 16px;
    transition: 1s;
    fill: #263632;
}

.popup {
    position:absolute;
    top: 80px;
    right: 0px;
    z-index: 1;

    background-color: rgb(255, 255, 255);
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    margin: 0 2%;
    border-radius: 0 0 10px 10px;
    display: none;
    flex-direction: column;
    transition: height,width 1s;
    opacity: 0.9;
}

.popup p {
    pointer-events: none;
}

.menuItem {
    padding: 0 25px;
}
.menuItem:hover {
    background-color:#CBDCF4;
    border-radius: 10px;
}

a:link {
    color: white;
    text-decoration: none;
}
a:visited {
    color: white;
    text-decoration: none;
}
a:hover {
    color: #CBDCF4;
}
a:active {
    color: darkorchid;
}

h2 {
    color: #263632;
    cursor: pointer;
}
h2:hover {
    color: #458ff8;
    cursor: pointer;
}
svg {
    stroke: #263632;
    fill: white;
}
svg:hover {
    stroke: #458ff8;
    fill: white;
    cursor: pointer;
}

/*
*
* // website styling defaults to mobile platforms, alternative styling applied for desktop...
*/
@media screen and (min-width: 500px) {
    .header {
        height: 100px;
    }
    .header .container {
        padding: 2.5% 2.5% 1% 2.5%;
    }

    .header .container h2 {
        font-size: 2em;
    }

    .popup {
        top: 100px;
    }
}