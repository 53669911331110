.carousel {
    padding: 2.5%;
    display: flex;
    justify-content: center;
    height: 17em;
    gap: 2%;
}

.carousel:hover {
    color: #CBDCF4;
    cursor: pointer;
}

.carousel div {
    display: flex;
    justify-content: center;
    background-color: #9FA69A;
    border-radius: 0 0 10px 10px;
    width: 100%;
    
}

.carousel h5 {
    margin-block: 10px;
    color: #FFF;
    font-weight: 600;
}
  
.carousel img {
    object-fit: cover;
    object-position: center;
    width: auto;
    height: 15em;
    border-radius: 10px 10px 0 0;
    
}

.carousel figure {
    display: flex;
    flex-direction: column;
    margin-block: 0;
    margin-inline: 0;
    width: 32%;
    height: auto;
    box-shadow: 1px 5px 5px rgba(37, 36, 36, 0.1);
    border-radius: 10px;
    transition: all 0.5s ease-in-out;
}

.carousel figure:hover {
    box-shadow: inset 0 0 10px 10px rgba(0, 0, 0, 0.1),
                1px 5px 5px rgba(37, 36, 36, 0.1);
}

.carousel .overlay {
    background-color: gray;
}

.figure {
    box-shadow: none;
}

.figure div h5 {
    pointer-events: none;
}

@media screen and (min-width: 500px) {
    .carousel {
        height: 20em;
    }
    .carousel img {
        height: 20em;
        overflow: hidden;
    }

    .carousel h5 {
        font-size: smaller;
    }
}

@media screen and (min-width: 900px){
    .carousel {
        height: 30em;
    }
    .carousel img {
        height: 30em;
        overflow: hidden;
    }

    .carousel h5 {
        font-size: medium;
    }
}

@media screen and (min-width: 1500px){
    .carousel {
        height: 40em;
    }
    .carousel img {
        height: 40em;
        overflow: hidden;
    }

    .carousel h5 {
        font-size: larger;
    }
}