.intro {
  width: 100%;
  height: auto;
  font-family: Inter, sans-serif;
  /*color: #fff;*/
  color: #263632;
}

.intro .greenBackground {
  /*background-color: #9fa69a;
  box-shadow: 0 5px 5px rgba(37, 36, 36, 0.1);*/
  color: #263632;
  display: flex;
  align-items: center;
  padding: 6%;
}

.intro h3 {
  margin-block: 0;
  color: #263632;
  font-size: small;
  pointer-events: none !important;
}

.intro p {
  margin-block: 0.5em 0;
  font-size: small;
  color: #263632;
}

.intro div {
  display: flex;
}

.intro div div {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.intro img {
  height: 7em;
  width: 7em;
  /*max-width: 180px;*/
  border-radius: 50%;
}

@media screen and (min-width: 500px){
  .intro p, .intro h3 {
    font-size: small;
  }

  .intro img {
    height: 10em;
    width: 10em;
  }
}

@media screen and (min-width: 900px){
  .intro .greenBackground {
    padding: 3%;
    width: 60%;
  }

  .intro p, .intro h3 {
    font-size: medium;
  }

  .intro div div {
    margin-left: 30px;
  }

  .intro img {
    height: 12em;
    width: 12em;
  }
}

@media screen and (min-width: 1500px){
  .intro .greenBackground {
    padding: 3%;
  }

  .intro p, .intro h3 {
    font-size: large;
  }

  .intro div div {
    margin-left: 30px;
  }

  .intro img {
    height: 20em;
    width: 20em;
    max-width: 20em;
  }
}